<template>
  <div
    id="container"
    class="container"
  >
    <!-- Sign In Form -->
    <div class="form-container sign-in-container">
      <form @submit.prevent="login">
        <h1 class="black--text">
          Sign in
        </h1>
        <input
          v-model="formData.email"
          type="email"
          placeholder="Email"
          required
        >
        <input
          v-model="formData.password"
          type="password"
          placeholder="Password"
          required
        >
        <p
          v-if="loginErrorResponse.msg"
          class="mt-0 mb-0 pa-2 red--text font-weight-medium"
        >
          {{ loginErrorResponse.msg }}
        </p>
        <router-link to="/forget-password">
          Forgot your password?
        </router-link>
        <p class="mt-0 black--text font-weight-medium">
          Don't have an account?
          <router-link to="/signup">
            <span class="blue--text text-subtitle-2">No Worries!! Sign up here</span>
          </router-link>
        </p>
        <button type="submit">
          Sign In
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { createOrUpdate } from "@/utils/api-request";
import { mapGetters } from "vuex";
import loadInitialData from "../../utils/initial-data";

export default {
  name: "Login",
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  data() {
    return {
      formData: {},
      loginErrorResponse: {},
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
  },
  methods: {
    async login() {
      const onSuccessRedirect = this.$route.query.onSuccess;
      try {
        this.$ebus.$emit("GLOBAL_LOADER_ON");
        const response = await createOrUpdate({
          path: "auth-token",
          data: this.formData,
        });
        if (response.status === 200) {
          this.$ebus.$emit("LOGIN_SUCCESSFULL");
          this.$store.dispatch("updateAccessToken", response.data.data);
          await loadInitialData(this.$store);
          this.$ebus.$emit("GLOBAL_LOADER_OFF");
          if (onSuccessRedirect) {
            this.$router.push(onSuccessRedirect);
          } else {
            this.$router.push("/");
          }
        } else {
          this.$ebus.$emit("GLOBAL_LOADER_OFF");
          this.loginErrorResponse = response;
        }
      } catch (e) {
        this.$ebus.$emit("GLOBAL_LOADER_OFF");
        this.loginErrorResponse = e.response.data;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
* {
  box-sizing: border-box;
}
body {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h2 {
  text-align: center;
}
p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 12px;
}
a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
button:active {
  transform: scale(0.95);
}
button:focus {
  outline: none;
}
button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}
form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.75rem;
  height: 100%;
  text-align: center;
}
input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
.container {
  margin-top: 10%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 600px;
  max-width: 100%;
  min-height: 480px;
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}
.sign-in-container {
  left: 0;
  width: 100%;
  z-index: 2;
}
.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}
.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}
@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
}
.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}
.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.container.right-panel-active .overlay {
  transform: translateX(50%);
}
.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.show-on-mobile {
  display: none;
}
.overlay-left {
  transform: translateX(-20%);
}
.container.right-panel-active .overlay-left {
  transform: translateX(0);
}
.overlay-right {
  right: 0;
  transform: translateX(0);
}
.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}
.social-container {
  margin: 20px 0;
}
.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}
footer p {
  margin: 10px 0;
}
footer i {
  color: red;
}
footer a {
  color: #3c97bf;
  text-decoration: none;
}
/* Media Queries */
@media only screen and (max-width: 600px) {
  .overlay-container {
    position: relative;
  }
  .sign-in-container {
    width: 100%;
  }
  .sign-up-container {
    transform: unset !important;
    width: 100%;
  }
  .sign-up-container h1,
  .sign-in-container h1 {
    font-size: 1.5rem;
  }
  .show-on-mobile {
    display: block;
  }
}
</style>
